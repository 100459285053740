<template>
  <FormComponent
    title="Create Bid"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn
        color="primary"
        depressed
        @click="submitForm"
        :disabled="!allFormValidation"
      >
        Submit
      </v-btn>
      <v-btn color="secondary" class="ml-2" depressed @click="resetForm">
        Reset
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper non-linear flat>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form ref="customerContract" v-model="isValid">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Bidding Details
                  </span>
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    hide-details="auto"
                    :items="allContainerCategoryList"
                    itemText="name"
                    itemValue="id"
                    v-model="bidDetails.container_category"
                    :rules="[
                      (val) => !!val || 'Container Category is Required!',
                    ]"
                    label="Select Container Category *"
                    required
                    :error-messages="formErrors.container_category"
                    @input="
                      delete formErrors.container_category,
                        onContainerCategoryChange()
                    "
                  ></MultiSelect>
                </v-col>
             
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    :showAll="true"
                    label="Select Transporter(s)"
                    :items="transporterList"
                    itemText="company_name"
                    itemValue="id"
                    hide-details="auto"
                    :rules="[
                      (val) => !!val.length || 'Transporter is required!',
                    ]"
                    :multiple="true"
                    :chips="true"
                    chipWidthStyle="max-width: 3.7vw;"
                    v-model="bidDetails.transporter"
                    :error-messages="formErrors.transporter"
                    @change="delete formErrors.transporter"
                  >
                  </MultiSelect>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Job Type *"
                    :items="allJobType"
                    itemText="display_name"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Job Type is Required']"
                    hide-details="auto"
                    v-model="bidDetails.job_type"
                    :error-messages="formErrors.job_type"
                    @input="delete formErrors.job_type"
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'End Date *',
                      'hide-details': 'auto',
                      rules: [(val) => !!val || 'End Date is required!'],
                      'error-messages': formErrors.end_date,
                    }"
                    fieldType="datetime"
                    required
                    :min="today"
                    v-model="bidDetails.end_date"
                    @input="delete formErrors.end_date"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Expected Rate"
                    hide-details="auto"
                    v-model="bidDetails.expected_rate"
                    :error-messages="formErrors.expected_rate"
                    @input="delete formErrors.expected_rate"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <Autocomplete
                    :loading="listLoading"
                    :totalItems="laneCount"
                    :items="allLaneList"
                    @updateList="getLaneList"
                    label="Route Code *"
                    itemText="title"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Route is Required']"
                    hide-details="auto"
                    v-model="bidDetails.lane"
                    :error-messages="formErrors.lane"
                    @input="
                      delete formErrors.lane;
                      delete formErrors.job_type;
                    "
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Container Type *"
                    :items="allContainersType"
                    itemText="title"
                    itemValue="id"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'Container Type is Required']"
                    hide-details="auto"
                    v-model="bidDetails.container_type"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Container Count/Truck *"
                    hide-details="auto"
                    v-model="bidDetails.container_count"
                    :rules="[(v) => !!v || 'Container Count is Required']"
                    :error-messages="formErrors.container_count"
                    @input="delete formErrors.container_count"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Admin Remarks"
                    hide-details="auto"
                    v-model="bidDetails.admin_remarks"
                    :error-messages="formErrors.admin_remarks"
                    @input="delete formErrors.admin_remarks"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    DateField,
    InputField,
    Autocomplete,
    MultiSelect,
    FormComponent,
  },

  data() {
    return {
      bidDetails: {
        transporter: [],
        container_category: null,
      },

      formErrors: {},

      transporterList: [],
      allContainersType: [],
      allContainerCategoryList: [],

      allJobType: [],
      allLaneList: [],

      isValid: true,

      backRoute: {
        path: "/app/admin/bidding/list",
      },
      listLoading: false,
      laneCount: 0,
      today: new Date().toISOString().slice(0, 16),
    };
  },
  computed: {
    allFormValidation() {
      return this.isValid;
    },
    filteredTransporters() {
      if (!this.bidDetails.container_category) return this.transporterList;

      return this.transporterList
        .filter((transporter) => {
          return transporter.container_category.includes(
            this.bidDetails.container_category
          );
        })
        .map((transporter) => transporter.id);
    },
  },
  methods: {
    async onContainerCategoryChange() {
      await this.getTransporterList();
      await this.getContainersType();

      this.bidDetails.transporter = this.filteredTransporters;
    },

    getContainerCategoryList(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        ...params,
        limit: "all",
      };

      this.$api.containerCategory
        .getContainerCategoryList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.allContainerCategoryList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },

    async submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.bidDetails,
        container_type: this.bidDetails.container_type.type_name,
      };
      if (data.lane && typeof data.lane == "object") {
        data.lane = data.lane.id;
      }
      if (data.lane && typeof data.lane == "object") {
        data.lane = data.lane.id;
      }

      if (this.bidDetails.transporter && this.bidDetails.transporter.length) {
        data.bid_request = this.bidDetails.transporter.map((t) => ({
          transporter: t,
        }));
      }

      await this.$api.bidding
        .addBid(data)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Bid Created Successfully!",
            color: "success",
          });
          if (this.$route.query && this.$route.query.backRoute) {
            let path = this.$route.query.backRoute;
            this.$router.push(path);
          } else {
            this.$router.push("/app/admin/bidding/list");
          }
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't create Bid!",
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
        });
    },

    resetForm() {
      if (this.$refs.customerContract) {
        this.$refs.customerContract.reset();
      }
      this.contractDetails = {
        created_from: "Admin",
        customer_contract_rates: [{}],
        customer_contract_charges: [],
      };
    },

    async getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        container_category: this.bidDetails.container_category, // Pass container category ID
        limit: "all",
        ...params,
      };
      try {
        const res = await this.$api.company.getCompanyList(params);
        this.transporterList = res.data;
        // Update bidDetails.transporter with filtered transporters
        // this.bidDetails.transporter = this.filteredTransporters;

        this.$bus.$emit("showLoader", false);
      } catch (error) {
        console.error(error);
        this.$bus.$emit("showLoader", false);
      }
    },

    getContainersType() {
      this.$bus.$emit("showLoader", true);
      const params = {
        limit: "all",
        container_category: this.bidDetails.container_category,
      };
      this.$api.containerType
        .getContainerTypeSelectList(params)
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },

    checkQuery() {
      if (this.$route.query) {
        if (this.$route.query.backRoute) {
          this.backRoute.path = this.$route.query.backRoute;
        }
        if (this.$route.query.active_tab) {
          if (!this.backRoute.query) {
            this.backRoute.query = {};
          }
          this.backRoute.query.active_tab = this.$route.query.active_tab;
        }
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    // Now you can use the 'id' variable as needed
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        // this.getTransporterList();
        this.getContainersType();
        this.getLaneList();
        this.getCustomerContractOptions();
        this.checkQuery();
        this.getContainerCategoryList();
      }
    }
  },
};
</script>
