<template>
  <v-row class="ma-0">
    <v-col cols="1" class="Form-Columns align-self-center">
      <span class="text-h5">Legs</span>
      <v-badge
        v-if="totalLegs"
        top
        :content="totalLegs"
        overlap
        class="mb-4 ml-3"
      >
      </v-badge>
    </v-col>

    <v-col cols="10" class="pa-0">
      <v-row class="mx-0 mb-0 mt-2">
        <v-col cols="3" class="Form-Columns-Dense">
          <MultiSelect
            label="Select Transporter(S)"
            :items="allTransporter"
            itemText="company_name"
            itemValue="id"
            :showAll="false"
            :multiple="true"
            :chips="true"
            chipWidthStyle="max-width: 6.5vw;"
            hide-details="auto"
            v-model="filters.transporters"
            @input="itemsPerPageChanged(100)"
          />
        </v-col>
        <v-col cols="2" class="Form-Columns-Dense">
          <MultiSelect
            label="Branch"
            :items="allBranch"
            itemText="branch_code"
            itemValue="id"
            hide-details="auto"
            :clearable="true"
            v-model="filters.branch"
            @change="itemsPerPageChanged(100)"
          />
        </v-col>
        <v-col cols="2" class="Form-Columns-Dense">
          <MultiSelect
            label="Depot"
            :items="allDepot"
            itemText="depot_name"
            itemValue="id"
            hide-details="auto"
            :clearable="true"
            v-model="filters.depot"
            @change="itemsPerPageChanged(100)"
          />
        </v-col>
        <v-col cols="2" class="Form-Columns-Dense">
          <MultiSelect
            label="Status"
            :items="statusList"
            :multiple="true"
            :chips="true"
            chipWidthStyle="max-width: 8.0vw;"
            hide-details="auto"
            v-model="filters.status"
            @input="itemsPerPageChanged(100)"
          />
        </v-col>
        <v-col cols="1" class="Form-Columns-Dense">
          <MultiSelect
            label="Sub Hire"
            :items="allSubHire"
            itemText="text"
            itemValue="value"
            hide-details="auto"
            :clearable="true"
            v-model="filters.is_sub_hire"
            @change="itemsPerPageChanged(100)"
          />
        </v-col>
        <v-col cols="auto" class="Form-Columns-Dense">
          <v-switch
            dense
            label="Container Wise"
            hide-details="auto"
            class="mt-1"
            true-value="Yes"
            false-value="No"
            v-model="containerCentric"
            @change="itemsPerPageChanged(100)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="1" class="Form-Columns d-flex align-center text-right">
      <Columns
        :headers="headers"
        :gridColInstance="gridColumnApi"
        localStorageKey="adminLegList"
      />
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" depressed class="ml-2">
            <v-icon color="primary">mdi-information</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto">
              <div class="px-1">
                <v-icon>mdi-checkbox-blank-outline</v-icon>
                <span>Created</span>
              </div>
              <div
                class="px-1"
                v-for="(status, index) in statusList"
                :key="index"
              >
                <div v-if="status.color">
                  <v-icon :color="status.color">mdi-checkbox-blank</v-icon>
                  <span> {{ status.text }} </span>
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto" class="Form-Columns-Dense">
      <div class="d-flex align-center">
        <v-btn
          @click="previousDate('start')"
          icon
          small
          depressed
          color="primary"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <DateField
          ref="startDateField"
          :inputProps="{
            dense: true,
            label: 'Start Date',
            'hide-details': 'auto',
            style: 'max-width: 110px !important',
          }"
          v-model="filters.start_date"
          @input="
            itemsPerPageChanged(100);
            setDate('start');
          "
        />
        <v-btn @click="nextDate('start')" icon small depressed color="primary">
          <v-icon> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </v-col>

    <v-col cols="auto" class="Form-Columns-Dense lg-px-0">
      <div class="d-flex align-center">
        <v-btn
          @click="previousDate('end')"
          icon
          small
          depressed
          color="primary"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <DateField
          ref="endDateField"
          :inputProps="{
            dense: true,
            label: 'End Date',
            'hide-details': 'auto',
            style: 'max-width: 110px !important',
          }"
          v-model="filters.end_date"
          @input="
            itemsPerPageChanged(100);
            setDate('end');
          "
        />
        <v-btn @click="nextDate('end')" icon small depressed color="primary">
          <v-icon> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="3" class="Form-Columns-Dense">
      <v-text-field
        label="Search On Enter"
        outlined
        dense
        append-icon="mdi-magnify"
        clearable
        @click:clear="onEnter()"
        hide-details="auto"
        v-model="filters.search"
        v-on:keyup.enter="onEnter"
      ></v-text-field>
    </v-col>

    <v-col cols="auto" class="Form-Columns-Dense align-self-center">
      <v-btn
        v-if="permissions && permissions.add"
        depressed
        small
        color="primary"
        @click="showMarkDAReadyForInvoiceForm = true"
        class="mr-1"
      >
        Ready For Invoice
      </v-btn>
      <v-btn
        depressed
        small
        color="primary"
        @click="showAttachmentsDialog = true"
        class="mr-1"
      >
        PODs
      </v-btn>
      <v-btn
        depressed
        small
        color="primary"
        @click="showLegExportDialog = true"
        class="mr-1"
      >
        Advance Search
      </v-btn>
      <v-btn
        depressed
        small
        color="primary"
        @click="showBulkOperationsDialog = true"
        class="mr-1"
        v-if="currentProfile && currentProfile.is_primary_user"
      >
        Bulk Operations
      </v-btn>
      <v-btn
        v-if="selectedLags && selectedLags.length && isSubHireId"
        depressed
        small
        color="primary"
        @click="completeLegs()"
        class="mr-1"
      >
        Complete Sub Hire Legs
      </v-btn>
      <v-btn
        v-if="selectedLags && selectedLags.length"
        depressed
        small
        color="primary"
        @click="showMovementDateDialog = true"
        class="mr-1"
      >
        Change Date
      </v-btn>

      <span class="text-h6 font-weight-regular">
        Total Selected Leg's -
        <span class="font-weight-bold"> {{ movementDateCount }} </span>
      </span>
    </v-col>
    <v-col cols="12" class="py-2">
      <AgGridVue
        style="width: 100%; height: calc(100vh - 232px)"
        class="small-table ag-theme-alpine"
        id="legsGrid"
        :columnDefs="headers"
        :context="context"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef"
        :grid-options="gridOptions"
        :isRowSelectable="isRowSelectable"
        rowSelection="multiple"
        @selection-changed="onSelectionChanged"
        :suppressDragLeaveHidesColumns="true"
        :rowData="allLegList"
      ></AgGridVue>
    </v-col>

    <v-col v-if="totalLegs > 0" cols="12" class="py-0 d-flex justify-end">
      <Pagination
        :pageNo="pageNo"
        :totalItems="totalLegs"
        :pageSize="itemsPerPage"
        @itemsPerPageChange="itemsPerPageChanged"
        @prevPage="prevPage"
        @nextPage="nextPage"
      />
    </v-col>

    <CombineLegForm
      v-model="showCombineLegForm"
      @refreshList="getLegsList()"
      :legId="selectedLegId"
      :companyId="transporterCompanyId"
    />
    <AdminTripForm
      v-model="showAdminTripForm"
      :legId="selectedLegId"
      @refreshList="getLegsList()"
      :transporterCompanyId="transporterCompanyId"
      @closeTripForm="closeTripForm"
    />
    <AdminLegExportDialog
      v-model="showLegExportDialog"
      @refreshList="getLegsList()"
    />
    <BulkOperationsDialog
      v-model="showBulkOperationsDialog"
      :legs="selectedLags"
      @refreshList="getLegsList()"
    />
    <SubhireForm
      v-model="showSubhireDialog"
      :legNumber="selectedrowData.leg_id"
      :daNumber="selectedrowData.da_no"
      :subHireNumber="selectedrowData.sub_hire_no"
      :legId="selectedrowData.id"
      :subHireID="selectedrowData.leg_sub_hire"
      :customerId="selectedrowData.customer_name"
      :daContractRate="selectedrowData.customer_rate"
      :jobStatus="selectedrowData.sub_hire_status"
      :containerType="selectedrowData.leg_container_details"
      @refreshList="getLegsList()"
    />
    <AdminAttachmentsDownload v-model="showAttachmentsDialog" />
    <MarkDAReadyForInvoice
      v-model="showMarkDAReadyForInvoiceForm"
      @refreshList="getLegsList()"
    />
    <CustomTextFieldDialog
      v-model="showDARemarksDialog"
      :fieldValue="selectedDARemarks"
      :dialogTitle="daRemarksDialog"
      :objID="selectedDAID"
      @refreshList="getLegsList()"
    />
    <ManualSequenceDialog
      v-model="showManualSequenceDialog"
      :fieldValue="selectedManualSequence"
      :dialogTitle="legManualSequenceDialogTitle"
      :objID="selectedLegID"
      @refreshList="getLegsList()"
    />
    <LegETADialog
      v-model="showlegETADialog"
      :fieldValue="selectedLegETA"
      :dialogTitle="legETADialogTitle"
      :objID="selectedLegID"
      @refreshList="getLegsList()"
    />
    <LegContainerSealNoDialog
      v-model="showlegContainerSealNoDialog"
      :dialogTitle="legContainerSealNoDialogTitle"
      :objID="selectedLegID"
      @refreshList="getLegsList()"
    />
    <DAHazardousForm
      v-model="showHazardousDialog"
      :dialogTitle="daHazardousFormDialog"
      :hazardousId="selectedHazardousId"
      :objID="selectedDAID"
      @refreshList="getLegsList()"
    />
    <LegMovementDateForm
      v-model="showMovementDateDialog"
      :legs="selectedLags"
      module="legs"
      @refreshList="
        getLegsList();
        selectedLags = [];
      "
    />
  </v-row>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminTripSchedulingButton from "@/components/AgGridComponents/AdminTripSchedulingButton.vue";
import CombineLegForm from "@/components/ModuleBased/dialogForms/CombineLegForm.vue";
import AdminTripForm from "@/components/ModuleBased/dialogForms/AdminTripForm.vue";
import MarkDAReadyForInvoice from "@/components/ModuleBased/dialogForms/MarkDAReadyForInvoice.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import AdminLegExportDialog from "@/components/ModuleBased/dialogForms/AdminLegExportDialog.vue";
import BulkOperationsDialog from "@/components/ModuleBased/dialogForms/BulkOperationsDialog.vue";
import SubhireForm from "@/components/ModuleBased/dialogForms/SubhireForm.vue";
import LegMovementDateForm from "@/components/ModuleBased/dialogForms/LegMovementDateForm.vue";
import LegContainerSealNoDialog from "@/components/ModuleBased/dialogForms/LegContainerSealNoDialog.vue";
import DAHazardousForm from "@/components/ModuleBased/dialogForms/DAHazardousForm.vue";
import ManualSequenceDialog from "@/components/ModuleBased/dialogForms/ManualSequenceDialog.vue";
import LegETADialog from "@/components/ModuleBased/dialogForms/LegETADialog.vue";
import CustomTextFieldDialog from "@/components/ModuleBased/dialogForms/CustomTextFieldDialog.vue";
import AdminAttachmentsDownload from "@/components/ModuleBased/dialogForms/AdminAttachmentsDownload.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
export default {
  components: {
    LegMovementDateForm,
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    agColumnHeader: CustomHeader,
    AdminTripSchedulingButton,
    CustomTextFieldDialog,
    CombineLegForm,
    DAHazardousForm,
    AdminTripForm,
    LegETADialog,
    InputField,
    MultiSelect,
    BulkOperationsDialog,
    AdminLegExportDialog,
    AdminAttachmentsDownload,
    LegContainerSealNoDialog,
    DateField,
    ManualSequenceDialog,
    MarkDAReadyForInvoice,
    SubhireForm,
  },
  data() {
    return {
      getListCalled: false,
      sorting: {},
      transporterCompanyId: null,
      all: true,

      showlegContainerSealNoDialog: false,
      selectedLegContainers: null,
      legContainerSealNoDialogTitle: null,
      legListTimeout: null,
      movementDateCount: 0,

      showlegETADialog: false,
      selectedLegETA: null,
      legETADialogTitle: null,

      showManualSequenceDialog: false,
      selectedManualSequence: null,
      legManualSequenceDialogTitle: null,

      selectedLegID: null,

      showHazardousDialog: false,
      selectedHazardousId: null,
      daHazardousFormDialog: null,

      showDARemarksDialog: false,
      selectedDAID: null,
      selectedDARemarks: null,
      daRemarksDialog: null,

      showLegExportDialog: false,
      showBulkOperationsDialog: false,
      showAttachmentsDialog: false,
      showMovementDateDialog: false,
      showSubhireDialog: false,
      headers: [
        {
          headerName: "Depot",
          field: "depot",
          tooltipField: "depot",
          minWidth: 45,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "DA",
          field: "da_no",
          sortable: true,
          minWidth: 65,
          tooltipField: "da_status",

          cellStyle: (params) => {
            let style = {
              backgroundColor: params.data.status_color,
              unSortIcon: true,
            };

            if (this.daPermissions && this.daPermissions.view) {
              style.textDecoration = "underline";
            }
            return style;
          },

          onCellClicked: (params) => {
            if (this.daPermissions && this.daPermissions.view) {
              if (params && params.data && params.data.da_id) {
                const path = {
                  path: `/app/admin/delivery-advice/details/${params.data.da_id}`,
                  query: {
                    backRoute: "/app/admin/trip/list",
                  },
                };
                this.$router.push(path);
              }
            }
          },
        },
        {
          headerName: "Invoice",
          field: "customer_invoice",
          sortable: true,
          minWidth: 80,
          tooltipField: "customer_invoice",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 65,
          tooltipField: "status",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Customer",
          field: "customer_name",
          sortable: true,
          minWidth: 65,
          tooltipField: "customer_name",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Haz",
          field: "is_hazmat",
          minWidth: 50,
          sortable: true,
          onCellClicked: (params) => {
            if (this.containerCentric === "No" && params.value == "Yes") {
              this.showHazardousDialog = true;
              this.selectedDAID = params.data.da_id;
              this.selectedHazardousId = params.data.da_hazardous_cargo_id;
              this.daHazardousFormDialog = `Hazardous Cargo Details - ${params.data.da_no}`;
            }
          },
          cellRenderer: function (params) {
            if (params.value == "Yes") {
              return `<span  class="red--text">${params.value}
                <i class="mdi mdi-alert"></i>
              </span>`;
            } else {
              return params.value;
            }
          },
          tooltipField: "is_hazmat",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Plan/Sequence",
          field: "manual_sequence",
          sortable: true,
          minWidth: 30,
          tooltipField: "manual_sequence",
          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showManualSequenceDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedManualSequence = params.data.manual_sequence;
              this.legManualSequenceDialogTitle = `Manual Sequence - ${params.data.leg_id}`;
            }
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Movement Date",
          field: "movement_date",
          sortable: true,
          checkboxSelection: true,
          minWidth: 100,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Time",
          field: "movement_time",
          tooltipField: "movement_time",
          minWidth: 40,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "ETA Time",
          field: "leg_eta_time",
          tooltipField: "leg_eta_time",
          sortable: true,
          minWidth: 50,
          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showlegETADialog = true;
              this.selectedLegID = params.data.id;
              if (params.data.leg_eta_time) {
                this.selectedLegETA = params.data.leg_eta_time.replace(
                  " ",
                  "T"
                );
              } else {
                this.selectedLegETA = null;
              }
              this.legETADialogTitle = `ETA Time - ${params.data.leg_id}`;
            }
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Source",
          field: "source_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "source_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "mid_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Dest",
          field: "destination_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "destination_address",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Trip Date",
          field: "trip_date",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Vehicle",
          field: "vehicle_number",
          tooltipField: "vehicle_number",
          minWidth: 65,
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_vehicle;
            } else {
              return params.value;
            }
          },
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Driver",
          field: "driver_name",
          tooltipField: "driver_name",
          minWidth: 65,
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_driver;
            } else {
              return params.value;
            }
          },
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Trailer 1",
          field: "trailer_1_number",
          tooltipField: "trailer_1_number",
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_trailer;
            } else {
              return params.value;
            }
          },
          sortable: true,
          minWidth: 65,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Trailer 2",
          field: "trailer_2_number",
          tooltipField: "trailer_2_number",
          minWidth: 65,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Container Type",
          field: "leg_container_details.types",
          tooltipField: "leg_container_details.types",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Count",
          field: "leg_container_details.count",
          minWidth: 30,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Container No(s)",
          field: "leg_container_details.numbers",
          tooltipField: "leg_container_details.numbers",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Bonus",
          field: "bonus",
          tooltipField: "bonus",
          minWidth: 100,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Weight",
          field: "leg_container_details.weights",
          tooltipField: "leg_container_details.weights",
          minWidth: 50,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Seal",
          field: "leg_container_details.seal_numbers",
          tooltipField: "leg_container_details.seal_numbers",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showlegContainerSealNoDialog = true;
              this.selectedLegID = params.data.id;
              this.legContainerSealNoDialogTitle = `Container Seal No - ${params.data.leg_id}`;
            }
          },
        },

        {
          headerName: `Total Amount (${this.$globals.currency})`,
          field: "da_total_rate",
          minWidth: 100,
          tooltipField: "da_total_rate",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: `Sell Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 55,
          tooltipField: "customer_contract_id",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Remarks",
          field: "da_remarks",
          tooltipField: "da_remarks",
          minWidth: 65,
          onCellClicked: (params) => {
            if (
              this.daPermissions &&
              this.daPermissions.add &&
              this.containerCentric === "No"
            ) {
              this.showDARemarksDialog = true;
              this.selectedDAID = params.data.da_id;
              this.selectedDARemarks = params.data.da_remarks;
              this.daRemarksDialog = `DA Remarks - ${params.data.da_no}`;
            }
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Company",
          field: "company_name",
          sortable: true,
          minWidth: 100,
          onCellClicked: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              this.showSubhireDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedrowData = params.data;
            }
          },
          tooltipField: "company_name",
          cellStyle: (params) => {
            if (params.data.sub_hire) {
              return {
                fontWeight: "bold",
                backgroundColor: params.data.status_color,
              };
            }
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          minWidth: 80,
          onCellClicked: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              this.showSubhireDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedrowData = params.data;
            }
          },
          cellStyle: (params) => {
            if (params.data && params.data.sub_hire) {
              return {
                fontWeight: "bold",
                backgroundColor: params.data.status_color,
              };
            }
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Sub Hire ID",
          field: "sub_hire_no",
          minWidth: 100,
          checkboxSelection: (params) => {
            if (
              params &&
              params.data &&
              params.data.is_sub_hire == "Yes" &&
              params.data.leg_sub_hire
            ) {
              return true;
            }
          },
          cellStyle: (params) => {
            if (params.data && params.data.sub_hire) {
              return {
                fontWeight: "bold",
                backgroundColor: params.data.status_color,
              };
            }
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Job Type No",
          field: "job_type",
          tooltipField: "job_type",
          minWidth: 60,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Start Time",
          field: "leg_start_date_time",
          minWidth: 70,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Finish Time",
          minWidth: 70,
          field: "leg_end_date_time",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: `DA Rate (${this.$globals.currency})`,
          field: "customer_rate",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
          minWidth: 100,
          cellRenderer: (params) => {
            return params.data.is_sub_hire === "No" ? params.value : "";
          },
        },

        {
          headerName: `Buy Rate (${this.$globals.currency})`,
          field: "leg_rate",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
          minWidth: 58,
          cellRenderer: (params) => {
            return params.data.is_sub_hire === "Yes" ? params.value : "";
          },
        },

        {
          headerName: "BOE",
          field: "boe",
          minWidth: 60,
          sortable: true,
          tooltipField: "boe",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 75,
          tooltipField: "status",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Leg Type",
          field: "leg_type",
          minWidth: 75,
          tooltipField: "leg_type",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Trip",
          field: "trip_id",
          tooltipField: "trip_status",
          minWidth: 70,
          sortable: true,
          cellStyle: (params) => {
            let style = {
              backgroundColor: params.data.status_color,
            };

            if (this.permissions && this.permissions.change) {
              style.textDecoration = "underline";
            }
            return style;
          },

          onCellClicked: (params) => {
            if (
              this.permissions &&
              this.permissions.change &&
              params.data &&
              params.data.trip_pk
            ) {
              const path = {
                path: `/app/admin/trip/details/${params.data.trip_pk}`,
                query: {},
              };
              this.$router.push(path);
            }
          },
        },
        {
          headerName: "Branch",
          field: "branch",
          sortable: true,
          minWidth: 50,
          tooltipField: "branch",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 65,
          maxWidth: 95,
          cellRendererFramework: "AdminTripSchedulingButton",
        },
      ],
      tabs: null,
      showAdminTripForm: false,
      showMarkDAReadyForInvoiceForm: false,
      allLegList: [],
      allTransporter: [],
      allBranch: [],
      allDepot: [],
      allSubHire: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],

      showCombineLegForm: false,
      selectedLegId: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalLegs: 100,
      containerCentric: "No",

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        onColumnMoved: () => {
          this.$bus.$emit("syncPreferences");
        },
        rowHeight: 30,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },

      // Filter vars
      filters: {
        status: [],
        transporters: [],
      },
      permissions: null,
      daPermissions: null,
      isSubHireId: false,
      statusList: [
        {
          text: "Created",
          value: "Created",
        },
        {
          text: "Assigned",
          value: "Assigned",
          color: "#FFFF00",
        },
        {
          text: "Enroute",
          value: "Enroute",
          color: "#808080",
        },
        {
          text: "Reached Drop Location",
          value: "Reached Drop Location",
          color: "#ffa500",
        },
        {
          text: "Completed",
          value: "Completed",
          color: "#44A9F4",
        },
        {
          text: "Ready For Invoice",
          value: "Ready For Invoice",
          color: "#78D78E",
        },
        {
          text: "Invoice Generated",
          value: "Invoice Generated",
          color: "#D7BDE2",
        },
      ],
      searchTimeout: null,
      selectedLags: [],
      selectedrowData: [],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  watch: {
    tabs() {
      this.itemsPerPageChanged(100);
    },
    containerCentric(val) {
      this.gridColumnApi.setColumnVisible("actions", val === "No");
    },
  },
  methods: {
    onEnter() {
      this.pageNo = 1;
      this.getLegsList();
    },
    setDate(inputType) {
      if (
        inputType == "start" &&
        Date.parse(this.filters.start_date) > Date.parse(this.filters.end_date)
      ) {
        this.filters.end_date = this.filters.start_date;
      }

      setTimeout(() => {
        const field = this.$refs.endDateField;
        if (field) {
          field.setDefaultDateTime();
        }
      }, 500);

      if (
        inputType == "end" &&
        Date.parse(this.filters.start_date) > Date.parse(this.filters.end_date)
      ) {
        this.filters.start_date = this.filters.end_date;
      }

      setTimeout(() => {
        const field = this.$refs.startDateField;
        if (field) {
          field.setDefaultDateTime();
        }
      }, 500);
    },
    nextDate(inputType) {
      if (inputType == "start" && this.$refs.startDateField) {
        const startField = this.$refs.startDateField;
        if (startField) {
          startField.nextDate();
        }
      }
      if (inputType == "end" && this.$refs.endDateField) {
        const endField = this.$refs.endDateField;
        if (endField) {
          endField.nextDate();
        }
      }
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    previousDate(inputType) {
      if (inputType == "start") {
        if (this.$refs.startDateField) {
          const startField = this.$refs.startDateField;
          if (startField) {
            startField.previousDate();
          }
        }
      }
      if (inputType == "end") {
        if (this.$refs.endDateField) {
          const endField = this.$refs.endDateField;
          if (endField) {
            endField.previousDate();
          }
        }
      }
    },
    onSelectionChanged() {
      this.selectedLags = this.gridApi.getSelectedRows();
      this.movementDateCount = this.selectedLags.length;

      for (let i = 0; i < this.selectedLags.length; i++) {
        if (this.selectedLags[i].leg_sub_hire) {
          this.isSubHireId = true;
        } else {
          this.isSubHireId = false;
          return;
        }
      }
    },
    isRowSelectable(params) {
      return (
        this.containerCentric == "No" &&
        !!params.data &&
        params.data.status == "Created"
      );
    },
    allChecked() {
      if (this.all) {
        this.filters.status = [
          "Created",
          "Assigned",
          "Enroute",
          "Reached Drop Location",
          "Ready For Invoice",
          "Completed",
        ];
        this.itemsPerPageChanged(100);
      } else {
        this.filters.status = [];
        this.itemsPerPageChanged(100);
      }
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    closeTripForm() {
      this.showAdminTripForm = false;
    },
    resetFilters() {
      this.filters = {
        status: [],
      };
      this.itemsPerPageChanged(100);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    search() {
      this.movementDateCount = 0;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageNo = 1;
        this.getLegsList();
      }, 1000);
    },
    itemsPerPageChanged(e) {
      this.movementDateCount = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLegsList();
    },
    prevPage() {
      this.movementDateCount = 0;
      this.pageNo--;
      this.getLegsList();
    },
    nextPage() {
      this.movementDateCount = 0;
      this.pageNo++;
      this.getLegsList();
    },
    applyGridSort(key, type) {
      this.itemsPerPageChanged(this.itemsPerPage);
    },
    getLegsList(params = {}) {
      if (this.legListTimeout) {
        clearTimeout(this.legListTimeout);
      }

      this.legListTimeout = setTimeout(() => {
        this.$bus.$emit("showLoader", true);
        params = {
          offset: this.offset,
          limit: this.itemsPerPage,
          ...params,
          ...this.filters,
        };

        delete params.sorting;

        if (this.sorting && Object.keys(this.sorting)) {
          let ordering = Object.keys(this.sorting).map((key) => {
            if (this.sorting[key] == true) {
              return key;
            }
            if (this.sorting[key] == false) {
              return `-${key}`;
            }
          });
          if (ordering && ordering.length) {
            params.ordering = ordering.join();
          }
        }

        if ("status" in params && params.status.length > 0) {
          params = { ...params, status: params.status.join(",") };
        }
        if ("transporters" in params && params.transporters.length > 0) {
          params = { ...params, companies: params.transporters.join(",") };
        }

        let function_name = "getLegList";

        if (this.containerCentric === "Yes") {
          function_name = "getLegContainerList";
        }

        this.setFilters();
        this.isSubHireId = false;
        this.selectedLags = [];
        this.$api.trip[function_name](params)
          .then((res) => {
            this.allLegList = res.data;
            this.totalLegs = res.count;
            this.$bus.$emit("showLoader", false);
            this.movementDateCount = 0;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 100);
    },
    getTransporterList(params = {}) {
      params = {
        offset: this.offset,
        limit: "all",
        company_types: "Transporter,Admin",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporter = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    viewTrip(id) {
      this.$router.push({
        path: `/app/admin/trip/details/${id}`,
        query: {},
      });
    },
    combineLegs(legId, transporterCompanyId) {
      this.selectedLegId = legId;
      this.transporterCompanyId = transporterCompanyId;
      this.showCombineLegForm = true;
    },
    deAllocateTransporter(leg) {
      if (confirm("Are you sure you want to unallocate transporter?")) {
        this.$api.trip
          .deAllocateTransporter(leg.id)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.getLegsList();
            this.movementDateCount = 0;
            this.$bus.$emit("showToastMessage", {
              message: "Transporter Unallocated Successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't udpate transporter!",
              color: "error",
            });
          });
      }
    },
    allocateAsset(leg) {
      this.selectedLegId = leg.id;
      this.showAdminTripForm = true;
    },

    checkQuery() {
      if (this.$route.query && this.$route.query.active_tab) {
        this.tabs = parseInt(this.$route.query.active_tab);
      }
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchList(params)
        .then((res) => {
          this.allBranch = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    checkFilters() {
      const filters = localStorage.getItem("trip_scheduling_filters");
      const user_data = JSON.parse(localStorage.getItem("userProfile"));

      if (filters) {
        this.filters = JSON.parse(filters);
        if (this.filters.sorting) {
          this.sorting = { ...this.filters.sorting };
          this.headers.map((obj) => {
            if (obj.field in this.sorting) {
              if (this.sorting[obj.field] == false) {
                obj.sort = "desc";
              } else if (this.sorting[obj.field]) {
                obj.sort = "asc";
              }
            }
          });
        }
      } else {
        this.filters.start_date = new Date().toISOString().slice(0, 10);
        this.filters.end_date = new Date().toISOString().slice(0, 10);
      }
      if (user_data && user_data.depot) {
        this.filters.depot = user_data.depot;
      }
      if (user_data && user_data.branch) {
        this.filters.branch = user_data.branch;
      }
    },
    setFilters() {
      if (
        this.filters &&
        Object.keys(this.filters).length > 0 &&
        Object.keys(this.filters).indexOf("ordering")
      ) {
        delete this.filters.ordering;
      }
      localStorage.setItem(
        "trip_scheduling_filters",
        JSON.stringify({ ...this.filters, sorting: this.sorting })
      );
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resetForm() {
      this.selectedLags = [];
    },
    completeLegs() {
      const config = {
        status: "secondary",
        title: "Sub Hire Job DAs",
        text: "Are you sure you want to complete the DAs?",
      };

      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        this.formErrors = {};
        let params = {
          legs: this.selectedLags.map((leg) => leg.id),
        };

        this.$api.deliveryAdvice
          .completeSubHireLeg(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Delivery Advices updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.getLegsList();
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't udpate delivery advices!",
              color: "error",
            });
            if (err && err.data) {
              this.formErrors = err.data;
            }
          });
      });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("leg_trip");
      this.daPermissions = getPermissions("delivery_advice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.checkFilters();
          this.getLists([
            this.checkQuery(),
            this.getLegsList(),
            this.getTransporterList(),
            this.getBranchList(),
            this.getDepotList(),
          ]);
        }
      }
    }
  },
};
</script>
