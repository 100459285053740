<template>
  <ListComponent title="Credit Note">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchInvoice"
          ></v-text-field>
          <!-- 
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip> -->

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              Pending
              <v-badge
                v-if="totalCreditNoteStatistics.credit_note_pending"
                top
                :content="totalCreditNoteStatistics.credit_note_pending"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Accepted<v-badge
                v-if="totalCreditNoteStatistics.credit_note_accepted"
                top
                :content="totalCreditNoteStatistics.credit_note_accepted"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <!-- <v-tab> Rejected </v-tab> -->
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allCreditNoteList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent v-model="invoiceLogDetailsDrawer" :resizable="true">
        <template #details>
          <v-card elevation="0">
            <v-card-title class="heading">
              <span class="text-h6">Credit Note Log</span>
            </v-card-title>

            <v-card-text>
              <v-row class="Order-Log-Scrolling-Class mt-3">
                <v-col cols="12">
                  <span
                    class="py-12"
                    v-if="invoice_logs && invoice_logs.length == 0"
                  >
                    No logs to show!
                  </span>

                  <v-timeline dense clipped v-else>
                    <v-timeline-item
                      v-for="(log, i) in invoice_logs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.updated_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </DetailsParent>
      <RemarksDialog
        v-model="showRemarksForm"
        :formDetails="updatingInvoice"
        :formErrors="formErrors"
        fieldKey="customer_remarks"
        :label="updatingInvoice.status + ' Remarks *'"
        @submitRemarks="updateInvoiceStatus"
      />
    </template>

    <template #actions> </template>
  </ListComponent>
</template>

<script>
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";

import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import AdminCreditNoteButton from "@/components/AgGridComponents/AdminCreditNoteButton.vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import AdminCustomerInvoiceFilters from "@/components/ModuleBased/dialogs/filters/AdminCustomerInvoiceFilters.vue";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    RemarksDialog,
    AdminCreditNoteButton,
    agColumnHeader: CustomHeader,
    Pagination,
    AdminCustomerInvoiceFilters,
    DetailsParent,
  },
  watch: {
    tab() {
      this.pageNo = 1;
      this.getCreditNoteList();
      this.invoiceLogDetailsDrawer = false;
    },
  },
  data() {
    return {
      showRemarksForm: false,

      formErrors: {},

      sorting: {},
      updatingInvoice: {},
      headers: [
        {
          headerName: "Credit No",
          field: "credit_no",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Credit Note Date",
          field: "credit_note_date",
          sortable: true,
          minWidth: 140,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Invoice No",
          field: "invoice_no",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          sortable: true,
          minWidth: 150,
        },

        {
          headerName: "Total Credit Amount",
          field: "total_credit_amount",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Full Invoice Credit Note",
          field: "full_invoice_credit_note",
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Remarks",
          field: "remarks",
          sortable: true,
          minWidth: 150,
        },

        {
          headerName: "Acknowledged By",
          field: "acknowledged_by",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Acknowledged On",
          field: "acknowledged_on",
          sortable: true,
          minWidth: 150,
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminCreditNoteButton",
          pinned: "right",
          minWidth: 310,
        },
      ],
      permissions: null,

      // details vars
      selectedInvoiceId: null,
      showDetails: false,

      // Pagination Vars
      showFiltersDialog: false,
      tab: null,
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      invoiceLogDetailsDrawer: false,
      invoice_logs: [],

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allCreditNoteList: [],
      totalCreditNoteStatistics: [],
      searchCreditNoteStatisticTimeout: null,
      daList: null,

      invoiceId: null,
      creditNoteID: null,
      downloadingInvoice: null,
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    viewCreditNOteLogs(creditNoteId) {
      this.invoiceLogDetailsDrawer = true;
      this.$bus.$emit("showLoader", false);
      this.$api.customerInvoice
        .getCreditNoteLogs(creditNoteId)
        .then((res) => {
          this.invoice_logs = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch invoice logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    async creditInvoiceDownload(params = {}) {
      params = {};
      this.$bus.$emit("showLoader", true);
      await this.$api.customerInvoice
        .getCreditNoteBlopObject(this.creditNoteID, params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          let blob = new Blob(["\ufeff", response.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          const objUrl = window.URL.createObjectURL(blob);
          link.href = objUrl;

          link.download = this.downloadingInvoice.credit_no + ".pdf";

          link.click();
          window.URL.revokeObjectURL(objUrl);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async downloadCreditNote(data, daList = null) {
    
      this.$bus.$emit("showLoader", true);
      this.creditNoteID = data.id;
      this.daList = daList;
      await this.$api.customerInvoice
        .getCreditNoteObject(this.creditNoteID)
        .then(async (res) => {
          this.downloadingInvoice = res.data;
          console.log("1222", this.downloadingInvoice);
          this.creditInvoiceDownload();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    updateInvoiceStatus() {
      this.$api.customerInvoice
        .partiallyUpdateInvoiceCredit(
          this.updatingInvoice.id,
          this.updatingInvoice
        )
        .then((res) => {
          this.getCreditNoteList();
          this.$bus.$emit("showToastMessage", {
            message: "Invoice status updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update invoice status!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    openRemarksForm(data, status) {
      this.updatingInvoice = {
        id: data.id,
        status: status,
        customer_remarks: null,
      };
      this.showRemarksForm = true;
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    searchInvoice() {
      this.pageNo = 1;
      this.getCreditNoteList();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCreditNoteList();
    },

    prevPage() {
      this.pageNo--;
      this.getCreditNoteList();
    },

    nextPage() {
      this.pageNo++;
      this.getCreditNoteList();
    },
    getTotalCreditNoteCount(params) {
      if (this.searchCreditNoteStatisticTimeout) {
        clearTimeout(this.searchCreditNoteStatisticTimeout);
      }
      this.searchCreditNoteStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        delete params.statuses;
        this.$api.customerInvoice
          .getCreditNoteListStatistics(params)
          .then((res) => {
            this.totalCreditNoteStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  Invoice!",
              color: "error",
            });
          });
      }, 1000);
    },

    getCreditNoteList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        status:
          this.tab == 0
            ? "Pending"
            : this.tab == 1
            ? "Accepted"
            : this.tab == 2
            ? "Rejected"
            : "Rejected",
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerInvoice
        .getCreditNoteList(params)
        .then((res) => {
          this.allCreditNoteList = res.data;
          this.totalItems = res.count;
          this.getTotalCreditNoteCount();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    viewCreditNote(id) {
      this.$router.push({
        path: `/app/admin/invoice/credit-note/details/${id}`,
        query: {
          active_tab: this.tab,
        },
      });
    },
    editCreditNote(invoiceDetails) {
      let path = `/app/admin/invoice/credit-note/edit/${invoiceDetails.id}`;

      this.$router.push({
        path: path,
        query: {
          active_tab: this.tab,
        },
      });
    },

    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
      }
      this.getTotalCreditNoteCount();
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("invoice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },

  mounted() {
    this.checkQuery();
    this.getTotalCreditNoteCount();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 160px);
  overflow-y: auto;
}
</style>
