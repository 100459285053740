<template>
  <v-row class="ma-0">
    <v-col cols="9" class="d-flex align-center">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            @click="callBackRoute"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>

      <span class="text-h5 mr-3">
        Trip Details - {{ tripDetails.trip_id }}
      </span>

      <v-chip> {{ tripDetails.status }}</v-chip>

      <v-menu open-on-hover right offset-y v-if="tripDetails">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" class="mx-4">
            mdi-map-marker-distance
          </v-icon>
        </template>
        <v-card>
          <v-card-title
            class="secondary white--text text-subtitle-1 font-weight-medium py-1"
          >
            Route Address
          </v-card-title>
          <v-card-text class="mt-2">
            <table class="Normal-Table">
              <tbody>
                <tr v-if="tripDetails.source_location_name">
                  <th>Source Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.source_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.mid_location_name">
                  <th>Mid Point Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.mid_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.destination_location_name">
                  <th>Destination Location</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.destination_location_name }}
                  </td>
                </tr>
                <tr v-if="tripDetails.source_address">
                  <th>Source Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.source_address }}
                  </td>
                </tr>
                <tr v-if="tripDetails.mid_address">
                  <th>Mid Point Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.mid_address }}
                  </td>
                </tr>
                <tr v-if="tripDetails.destination_address">
                  <th>Destination Address</th>
                  <th class="px-2">:</th>
                  <td>
                    {{ tripDetails.destination_address }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu
        open-on-hover
        right
        offset-y
        v-if="tripDetails.trip_leg_containers"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge overlap :content="tripDetails.trip_container_count">
            <v-icon v-on="on" v-bind="attrs" class="mx-2">
              mdi-train-car-container
            </v-icon>
          </v-badge>
        </template>
        <v-card>
          <v-card-title
            class="secondary white--text text-subtitle-1 font-weight-medium py-1"
          >
            Container Information
          </v-card-title>
          <v-card-text class="mt-2">
            <v-row>
              <v-col
                cols="12"
                v-for="(container, i) in tripDetails.trip_leg_containers"
                :key="i"
              >
                <ContainerCard
                  :container="container"
                  :showAddressDetails="false"
                  :isTransporter="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <div class="self-align-top ml-5">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              class="mx-2"
              @click="getNextPreviousTrip('previous')"
            >
              mdi-arrow-left-drop-circle
            </v-icon>
          </template>
          <span>Previous</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              class="mx-2"
              @click="getNextPreviousTrip('next')"
            >
              mdi-arrow-right-drop-circle
            </v-icon>
          </template>
          <span>Next</span>
        </v-tooltip>
      </div>
    </v-col>

    <v-col cols="3" class="text-right align-self-center">
      <v-btn
        color="primary"
        depressed
        @click="$bus.$emit('openTripChat', tripDetails.id)"
        class="mx-3"
      >
        Open Chat
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        depressed
        @click="showLegDialog = true"
      >
        Legs ({{ tripDetails.trip_leg_count }})
      </v-btn>
    </v-col>

    <v-col cols="12" class="heading">
      <v-row class="ma-0" justify="space-between">
        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.source_location_code">
              <span class="text-caption">Source Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.source_location_code }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.mid_location_code">
              <span class="text-caption">Mid Point Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.mid_location_code }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.destination_location_code"
            ></v-divider>
            <div v-if="tripDetails.destination_location_code">
              <span class="text-caption">Destination Location</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.destination_location_code }}
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.trip_date">
              <span class="text-caption">Trip Date</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_date | formatDate(false) }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.driver_name">
              <span class="text-caption">Driver Name</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.driver_name }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.vehicle_number">
              <span class="text-caption">Vehicle</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.vehicle_number }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.trailer_1_number">
              <span class="text-caption">Trailer 1</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trailer_1_number }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.trailer_2_number"
            ></v-divider>
            <div v-if="tripDetails.trailer_2_number">
              <span class="text-caption">Trailer 2</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trailer_2_number }}
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div v-if="tripDetails.channel">
              <span class="text-caption">Channel</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.channel }}
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div v-if="tripDetails.trip_start">
              <span class="text-caption">Actual Trip Start</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_start | formatDate(true) }}
              </p>
            </div>
            <v-divider
              vertical
              class="mx-3"
              v-if="tripDetails.trip_end"
            ></v-divider>
            <div class="ml-4" v-if="tripDetails.trip_end">
              <span class="text-caption">Actual Trip End</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_end | formatDate(true) }}
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="auto" class="Form-Columns-Dense">
          <div class="d-flex">
            <div>
              <span class="text-caption">Odometer Reading</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.vehicle_odometer_reading }} KM
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div>
              <span class="text-caption">Trip Start</span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_start_km }} KM
              </p>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div>
              <span class="text-caption">Trip End </span>
              <p class="font-weight-medium mb-0">
                {{ tripDetails.trip_end_km }} KM
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      class="pt-1"
      v-if="tripDetails && Object.keys(tripDetails).length > 0"
    >
      <v-row class="ma-0">
        <v-col cols="12" lg="8" class="Form-Columns-Dense">
          <v-btn
            color="primary"
            :disabled="!tripDetails.is_internal_trip"
            depressed
            @click="showChecklistForm = true"
          >
            Check list
          </v-btn>
          <v-btn
            class="ml-3"
            color="primary"
            depressed
            @click="showTimeStampDialog = true"
          >
            Change Time Stamp
          </v-btn>
        </v-col>

        <v-col cols="12" lg="4" class="Form-Columns-Dense">
          <div class="d-flex">
            <MultiSelect
              label="Select Milestones"
              :returnObject="false"
              :items="milestoneList"
              itemText="milestone_name"
              itemValue="id"
              :multiple="false"
              hide-details="auto"
              v-model="milestone"
            ></MultiSelect>

            <v-btn
              class="ml-2"
              color="primary"
              depressed
              :disabled="!milestone"
              @click="tripMilestoneReached"
            >
              Add
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="px-0">
          <v-slider
            v-model="tripNumaricalStatus"
            :tick-labels="tripMilestones"
            thumb-label="always"
            :max="4"
            class="pt-8 Milestone"
            step="1"
            ticks="always"
            tick-size="10"
            @change="updateTripStatus"
            :readonly="!tripDetails.is_internal_trip"
          >
          </v-slider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="heading">
      <v-row v-if="Object.keys(tripDetails).length > 0">
        <v-col cols="12" lg="8" class="pr-2">
          <GoogleMap
            ref="transporterTripDetailsMap"
            mapHeight="calc(100vh - 460px)"
            mapWidth="100%"
            :hasMapInfo="true"
          >
            <template #mapInfo>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="sourceMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Source
                    </span>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="midMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold"> Mid </span>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="destinationMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Destination
                    </span>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center pa-2">
                    <img style="width: 18px" :src="truckMarker" />
                    <span class="ml-4 text-body-2 font-weight-bold">
                      Vehicle
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </GoogleMap>
        </v-col>
        <!-- 
        <v-col cols="12" lg="4" class="px-2">
          <v-card>
            <v-card-text @scroll="getOldChats" id="chatCard" class="chatDialog">
              <v-btn
                elevation="12"
                v-if="newMessageAlert"
                class="new-msg-btn"
                @click="scrollChatToBottom"
              >
                New message
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
              <div
                class="message-group"
                v-for="(msg, i) in messageList"
                :key="i"
              >
                <v-row
                  v-if="msg.message_format == 'Text'"
                  :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
                  no-gutters
                  class="msg-container"
                >
                  <div
                    :class="`${
                      msg.sender_type == userType
                        ? 'rounded-br-0 grey lighten-2 send'
                        : 'rounded-bl-0 primary lighten-4 recieved'
                    }`"
                    class="message rounded-lg pa-4"
                  >
                    {{ msg.message }}
                  </div>
                </v-row>
                <v-row
                  v-if="msg.message_format == 'Image'"
                  :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
                  no-gutters
                  class="msg-container"
                >
                  <div
                    :class="`${
                      msg.sender_type == userType
                        ? 'rounded-br-0 grey lighten-2 send'
                        : 'rounded-bl-0 primary lighten-4 recieved'
                    }`"
                    class="message rounded-lg pa-4"
                  >
                    <img width="100%" :src="msg.attachment_url" alt="File" />
                  </div>
                </v-row>
                <v-row
                  v-if="msg.message_format == 'Document'"
                  :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
                  no-gutters
                  class="msg-container"
                >
                  <div
                    :class="`${
                      msg.sender_type == userType
                        ? 'rounded-br-0 grey lighten-2 send'
                        : 'rounded-bl-0 primary lighten-4 recieved'
                    }`"
                    class="message rounded-lg pa-4"
                  >
                    <v-list dense class="pa-0">
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-list-item
                            @click="downloadFile(msg.attachment_url)"
                            dense
                            v-bind="attrs"
                            v-on="on"
                            class="pa-0"
                            :class="`${
                              msg.sender_type == userType
                                ? 'grey lighten-2'
                                : 'primary lighten-4'
                            }`"
                          >
                            <v-list-item-avatar>
                              <v-icon size="20" class="grey lighten-1" dark>
                                mdi-file
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                v-text="getFileName(msg.attachment_url)"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <span>{{ getFileName(msg.attachment_url) }}</span>
                      </v-tooltip>
                    </v-list>
                  </div>
                </v-row>
                <v-row
                  v-if="msg.message_format == 'Audio'"
                  :justify="`${msg.sender_type == userType ? 'end' : 'start'}`"
                  no-gutters
                  class="msg-container"
                >
                  <div
                    :class="`${
                      msg.sender_type == userType
                        ? 'rounded-br-0 grey lighten-2 send'
                        : 'rounded-bl-0 primary lighten-4 recieved'
                    }`"
                    class="message rounded-lg pa-4"
                  >
                    <audio controls :src="msg.attachment_url">
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </div>
                </v-row>
                <div
                  :class="`${msg.sender_type == userType ? 'text-right' : ''}`"
                  class="text-caption"
                >
                  {{ msg.sender_type }} | {{ msg.sender_name }} |
                  {{ msg.created | formatDate(true) }}
                </div>
              </div>
              <input
                ref="fileSelector"
                @change="selectionChanged"
                v-show="false"
                type="file"
                accept="audio/mp3,audio/aac,audio/wav,image/png,image/jpg,image/jpeg,.doc,.docx,.pdf,.xlsx,.xls"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                icon
                depressed
                class="mx-2"
                @click="toggleRecording"
                :disabled="tripDetails.status == 'Completed'"
              >
                <v-icon>mdi-microphone</v-icon>
              </v-btn>
              <span v-if="recording"> Recording ... </span>
              <audio v-if="audio" controls :src="audioPreview">
                Your browser does not support the
                <code>audio</code> element.
              </audio>
              <v-text-field
                v-if="!recording && !audio"
                placeholder="Type Here..."
                prepend-inner-icon="mdi-attachment"
                v-model="message"
                filled
                rounded
                hide-details
                :disabled="tripDetails.status == 'Completed'"
                :readonly="!!selectedFile"
                :loading="uploadLoading"
                @keyup.enter="sendMessage"
                @click:prepend-inner="triggerFileSelect()"
              >
              </v-text-field>
              <v-btn
                v-if="selectedFile || audio || message"
                icon
                depressed
                @click="resetMessage()"
                class="mx-2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                :disabled="recording || tripDetails.status == 'Completed'"
                icon
                depressed
                @click="sendMessage()"
                class="mx-2"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->

        <v-col cols="12" lg="4" class="pl-2">
          <v-card class="logsContainer">
            <v-card-text>
              <v-timeline dense clipped>
                <v-timeline-item
                  v-for="(log, i) in tripDetails.trip_logs"
                  :key="i"
                  icon-color="primary"
                  small
                >
                  <v-card class="elevation-2">
                    <v-card-title class="py-0">
                      <v-row>
                        <v-col cols="6">
                          <span class="text-subtitle-2" v-if="log.created_by">
                            {{ log.created_by }}
                          </span>
                          <span class="text-subtitle-2" v-else>
                            {{ log.added_by }}
                          </span>
                        </v-col>

                        <v-col cols="6" class="text-right">
                          <span class="text-caption">
                            {{ log.log_timestamp | formatDate(true) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text> {{ log.message }} </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog max-width="50%" v-model="showChecklistForm">
      <v-card>
        <v-card-title class="px-2 py-0 d-flex">
          <v-row class="ma-0">
            <v-col cols="8" class="py-0">
              <v-tabs v-model="detailsTab">
                <v-tabs-slider></v-tabs-slider>
                <v-tab @click="tripCheckList">Trip Checklist</v-tab>
                <v-tab @click="defectsCheckList">Defective Checklist</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="2" class="py-0 text-right align-self-center">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on">
                    <v-icon
                      color="primary"
                      :disabled="!tripDetails.is_checklist_submitted"
                      @click="getTripDefectBlob"
                    >
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Checklist Download</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" class="py-0 text-right align-self-center">
              <v-btn icon @click="showChecklistForm = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider />
        <v-card-text class="white pa-0 rounded">
          <v-tabs-items class="rounded" v-model="detailsTab" v-if="!defects">
            <v-card-title>
              <v-col cols="8">
                <span> Trip Checklist</span>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-checkbox
                  v-model="selectAllCheckList"
                  label="Select All"
                  @change="selectAllCheckListChanged()"
                ></v-checkbox>
              </v-col>
            </v-card-title>

            <v-card-text>
              <v-tab-item>
                <v-row v-for="(check, i) in checkList" :key="i">
                  <v-col cols="8">
                    <template>
                      <v-icon
                        v-if="check.asset_type == 'Driver'"
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-card-account-details
                      </v-icon>
                      <v-icon
                        v-if="check.asset_type == 'Vehicle'"
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-truck-fast
                      </v-icon>
                      <v-icon
                        v-if="check.asset_type == 'Trailer'"
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-truck-trailer
                      </v-icon>
                      <span v-if="check.is_mandatory == 'Yes'">
                        {{ `${check.name} *` }}
                        <span class="text-caption ml-2"
                          >Asset Type : {{ check.asset_type }}
                        </span>
                      </span>
                      <span v-else>
                        {{ `${check.name}` }}
                        <span class="text-caption ml-2">
                          Asset Type : {{ check.asset_type }}
                        </span>
                      </span>
                    </template>
                  </v-col>
                  <v-col cols="4">
                    <v-switch
                      :readonly="
                        tripDetails.status != 'Assigned' ||
                        (defectiveCheckList.length > 0 &&
                          check.is_mandatory == 'Yes')
                      "
                      class="mt-1"
                      v-model="check.is_checked"
                      true-value="Yes"
                      false-value="No"
                      hide-details
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-3 py-1">
              <v-btn
                v-if="tripDetails.status == 'Assigned'"
                color="primary"
                :disabled="!ischeckFormValid || tripCheckListButton"
                @click="submitTripChecklist"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-tabs-items>

          <v-form ref="adminremarkForm" v-model="isValid">
            <v-tabs-items v-if="defects">
              <v-card-title>
                <v-row>
                  <v-col cols="6">
                    <span> Defective Checklist</span>
                  </v-col>
                  <v-col cols="3">
                    <span> Driver Remarks</span>
                  </v-col>
                  <v-col cols="3">
                    <span> Admin Remarks</span>
                    <!-- <v-tooltip bottom v-if="defectiveCheckList.length > 0">
                    <template #activator="{ on, attrs }">
                      <v-icon color="primary" v-on="on" v-bind="attrs"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ defectiveCheckList[0].driver_remarks }}</span>
                  </v-tooltip> -->
                  </v-col>
                </v-row>

                <!-- <v-btn icon @click="showChecklistForm = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
              </v-card-title>

              <v-card-text>
                <v-row v-for="(defect, i) in defectiveCheckList" :key="i">
                  <v-col cols="4">
                    <template>
                      <v-icon
                        v-if="
                          defect.trip_checklist &&
                          defect.trip_checklist.asset_type == 'Driver'
                        "
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-card-account-details
                      </v-icon>
                      <v-icon
                        v-if="
                          defect.trip_checklist &&
                          defect.trip_checklist.asset_type == 'Vehicle'
                        "
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-truck-fast
                      </v-icon>
                      <v-icon
                        v-if="
                          defect.trip_checklist &&
                          defect.trip_checklist.asset_type == 'Trailer'
                        "
                        color="primary"
                        class="ml-2 mr-2"
                      >
                        mdi-truck-trailer
                      </v-icon>
                      <span v-if="defect.trip_checklist.is_mandatory == 'Yes'">
                        {{ defect.trip_checklist.name }}*
                        <span class="text-caption ml-2">
                          Asset Type :
                          {{ defect.trip_checklist.asset_type }}
                        </span>
                      </span>
                      <span v-else>
                        {{ defect.trip_checklist.name }}
                        <span class="text-caption ml-2">
                          Asset Type :
                          {{ defect.trip_checklist.asset_type }}
                        </span>
                      </span>
                    </template>
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      :readonly="tripDetails.status != 'Assigned'"
                      class="mt-1"
                      v-model="defect.trip_checklist.is_checked"
                      true-value="Yes"
                      false-value="No"
                    >
                    </v-switch>
                  </v-col>

                  <v-col cols="3">
                    <InputField
                      readonly
                      type="text"
                      :clearable="false"
                      v-model="defect.driver_remarks"
                      label="Driver Remarks"
                      required
                    />
                  </v-col>
                  <v-col cols="3">
                    <InputField
                      :readonly="tripDetails.status != 'Assigned'"
                      type="text"
                      v-model="defect.remarks"
                      label="Admin Remarks *"
                      :rules="[
                        (val) => !!val || 'Planner remarks is mandatory',
                      ]"
                      required
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  v-if="tripDetails.status == 'Assigned'"
                  color="primary"
                  :disabled="
                    !ischeckFormValid ||
                    permissions & !permissions.add ||
                    !defectiveCheckList.length > 0 ||
                    !isValid ||
                    tripdefectCheckListButton
                  "
                  @click="submitDefectTripChecklist"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-tabs-items>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLegDialog" width="70%">
      <v-card v-if="tripDetails.trip_leg_containers">
        <v-card-title class="secondary white--text px-4 py-2">
          <span class="text-capitalize">Leg List</span>

          <v-spacer></v-spacer>

          <v-btn @click="showLegDialog = false" color="white" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-2">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 590px)"
            class="ag-theme-alpine"
            id="tripLegGrid"
            :columnDefs="tripLegHeaders"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="tripDetails.trip_legs"
          ></AgGridVue>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTimeStampDialog" width="70%">
      <v-card v-if="tripDetails.trip_leg_containers">
        <v-card-title class="secondary white--text px-4 py-2">
          <span class="text-capitalize">Time Stamp</span>

          <v-spacer></v-spacer>

          <v-btn
            @click="resetForm(), (showTimeStampDialog = false)"
            color="white"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-row class="ma-0" v-if="editTripLogMode">
            <v-col cols="12" lg="4" class="Form-Columns">
              <p class="text-h6 font-weight-medium mb-0">
                {{ tripLogDetails.message ? tripLogDetails.message : "Logs" }}
              </p>
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                ref="tripLogDateField"
                :inputProps="{
                  dense: true,
                  label: 'Time Stamp  *',
                  'hide-details': 'auto',
                  'error-messages': tripLogErrors.log_timestamp,
                }"
                fieldType="datetime"
                v-model="tripLogDetails.log_timestamp"
                :rules="[(val) => !!val || 'Date is Required!']"
              />
            </v-col>
            <v-col class="text-right" cols="12" lg="4">
              <v-btn color="primary" depressed @click="submitTripLogsForm">
                Update
              </v-btn>

              <v-btn
                class="ml-3"
                color="secondary"
                depressed
                @click="resetForm"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>

          <AgGridVue
            style="width: 100%; height: calc(100vh - 590px)"
            class="ag-theme-alpine"
            id="tripLegGrid"
            :columnDefs="TimeStampHeaders"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="tripDetails.trip_logs"
            :context="context"
          ></AgGridVue>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TripOdometerDialog
      v-model="showOdometerDialog"
      :newStatus="newStatus"
      :tripDetails="tripDetails"
      @refreshList="refreshTripDetails()"
      @revertTripStatus="revertTripStatus()"
    />
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import ContainerCard from "@/components/General/ContainerCard.vue";
import GoogleMap from "@/components/General/GoogleMap.vue";
import TripOdometerDialog from "@/components/ModuleBased/dialogForms/TripOdometerDialog.vue";

import {
  sourceMarker,
  destinationMarker,
  midMarker,
  truckMarker,
} from "@/assets/images/mapIcons.js";
import TimeStampButton from "@/components/AgGridComponents/TimeStampButton.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import { AgGridVue } from "ag-grid-vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    AgGridVue,
    MultiSelect,
    GoogleMap,
    ContainerCard,
    DateField,
    TimeStampButton,
    TripOdometerDialog,
  },
  TripDetails: {
    default: false,
  },
  computed: {
    userType() {
      return localStorage.getItem("user_type");
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      sourceMarker,
      destinationMarker,
      truckMarker,
      midMarker,
      waypoints: [],
      waypointNames: [],

      selectAllCheckList: false,
      showOdometerDialog: false,
      editTripLogMode: false,
      isValid: true,

      tripMilestones: [
        "Assigned",
        "Enroute",
        "Reached Pickup Location",
        "Reached Drop Location",
        "Completed",
      ],
      tripNumaricalStatus: 0,
      oldtripNumaricalStatus: 0,
      daDetails: {},
      // Trip Details
      tripDetails: {},
      tripLogErrors: {},

      backRoute: { path: "/transporter/trips/list" },

      // checklist form
      checkList: [],
      initialLoad: true,
      showChecklistForm: false,
      defectiveCheckList: [],
      defects: false,
      detailsTab: true,
      newStatus: null,
      milestone: null,
      milestoneList: [],
      ischeckFormValid: true,
      showLegDialog: false,
      showTimeStampDialog: false,

      directions: [],
      directionsTimeout: null,
      selectedRoute: {},
      markerTimeout: null,

      tripLogDetails: {},

      tripCheckListButton: false,
      tripdefectCheckListButton: false,

      driverLocationDetails: {},
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
      tripLegHeaders: [
        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 120,
        },
        {
          headerName: "DA",
          field: "da_no",
          minWidth: 120,
        },
        {
          headerName: "BOE",
          field: "boe",
          minWidth: 120,
        },
        {
          headerName: "Haz",
          field: "is_hazmat",
          minWidth: 60,
        },
        {
          headerName: "Gen",
          field: "has_genset",
          minWidth: 120,
        },
        {
          headerName: "Container No(s)",
          field: "leg_container_details.numbers",
          minWidth: 220,
          tooltipField: "leg_container_details.numbers",
        },
      ],
      TimeStampHeaders: [
        {
          headerName: "Logs",
          field: "message",
          minWidth: 120,
        },
        {
          headerName: "User",
          field: "added_by",
          minWidth: 120,
        },
        {
          headerName: "Date/Time",
          field: "log_timestamp",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          minWidth: 120,
        },
        {
          headerName: "Actions",
          cellRendererFramework: "TimeStampButton",
          minWidth: 60,
        },
      ],
    };
  },
  watch: {
    tripNumaricalStatus(newVal, oldVal) {
      this.oldtripNumaricalStatus = oldVal;
    },
    showChecklistForm(val) {
      if (val) {
        this.getChecklistData();
        this.getDefectiveChecklistData();
      }
    },
  },
  methods: {
    viewTripDetails(id) {
      this.$router.push({
        path: `/app/admin/trip/details/${id}`,
        query: { backRoute: `/app/admin/trip/list` },
      });
      setTimeout(() => {
        this.permissions = getPermissions("leg_trip");
        this.getTripDetails(this.$route.params.id);
        this.getTripMatrix(this.$route.params.id);
      }, 20);
    },
    getNextPreviousTrip(changeKey) {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.tripDetails.id,
        trip_change: changeKey,
      };
      this.$api.trip
        .prevNextTrip(payload)
        .then((res) => {
          this.viewTripDetails(res.data.trip_id);
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Trip not found!",
            color: "error",
          });
        });
    },

    async getTripDefectBlob() {
      this.$bus.$emit("showLoader", true);

      await this.$api.trip
        .getTripDefectsBlob(this.tripDetails.id)
        .then(async (resp) => {
          let blob = new Blob(["\ufeff", resp.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.tripDetails.trip_id + "_trip_checklist" + ".pdf";
          link.click();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    selectAllCheckListChanged() {
      if (
        this.selectAllCheckList &&
        this.checkList &&
        this.checkList.length > 0
      ) {
        this.checkList.forEach((element) => {
          element.is_checked = "Yes";
        });
      } else {
        this.checkList.forEach((element) => {
          element.is_checked = "No";
        });
      }
    },
    editTripLog(data) {
      this.tripLogDetails = data;
      if (this.tripLogDetails.log_timestamp) {
        this.tripLogDetails.log_timestamp =
          this.tripLogDetails.log_timestamp.replace(" ", "T");
      }

      setTimeout(() => {
        const field = this.$refs.tripLogDateField;
        if (field) {
          field.setDefaultDateTime();
        }
      }, 20);

      this.editTripLogMode = true;
    },

    resetForm() {
      this.tripLogDetails = {};
      this.editTripLogMode = false;
    },
    tripCheckList() {
      this.defects = false;
    },
    defectsCheckList() {
      this.detailsTab = false;
      this.defects = true;
      this.getDefectiveChecklistData();
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },

    getChecklistData() {
      this.$api.trip
        .getTripCheckList(this.tripDetails.id)
        .then((res) => {
          this.checkList = res.data.check_list;
          this.showChecklistForm = true;

          for (let i = 0; i < this.checkList.length; i++) {
            if (
              this.checkList[i].is_checked == "Yes" &&
              this.checkList[i].is_mandatory == "No"
            ) {
              this.tripCheckListButton = true;
              return;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getDefectiveChecklistData() {
      this.$api.trip
        .getDefectiveTripCheckList(this.tripDetails.id)
        .then((res) => {
          this.defectiveCheckList = res.data.defect_check_list;
          this.showChecklistForm = true;
          for (let i = 0; i < this.defectiveCheckList.length; i++) {
            if (this.defectiveCheckList[i].trip_checklist.is_checked == "Yes") {
              this.tripdefectCheckListButton = true;
              return;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    submitTripChecklist() {
      this.$api.trip
        .updateTripCheckList(this.tripDetails.id, this.checkList)
        .then((res) => {
          this.showChecklistForm = false;
          this.getTripDetails(this.$route.params.id);
        })
        .catch((err) => {
          this.tripNumaricalStatus = 0;
          this.$bus.$emit("showToastMessage", {
            message:
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors[0]
                : "Couldn't update Trip check list!",
            color: "error",
          });
        });
    },

    submitDefectTripChecklist() {
      this.$api.trip
        .updateDefectiveTripCheckList(
          this.tripDetails.id,
          this.defectiveCheckList
        )
        .then((res) => {
          this.showChecklistForm = false;
          this.getTripDetails(this.$route.params.id);
        })
        .catch((err) => {
          this.tripNumaricalStatus = 0;
          this.$bus.$emit("showToastMessage", {
            message:
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors[0]
                : "Couldn't update Trip check list!",
            color: "error",
          });
        });
    },

    updateTripStatus(val) {
      let validStatusForOdometer = false;
      this.oldStatus = this.tripDetails.status;
      this.newStatus = this.tripMilestones[val];

      // Check if status change is valid for odometer
      if (["Enroute", "Completed"].indexOf(this.newStatus) > -1) {
        if (this.newStatus === "Enroute" && this.oldStatus === "Assigned") {
          validStatusForOdometer = true;
        } else if (
          this.newStatus === "Completed" &&
          [
            "Assigned",
            "Enroute",
            "Reached Pickup Location",
            "Reached Drop Location",
          ].indexOf(this.oldStatus) > -1
        ) {
          validStatusForOdometer = true;
        }
      }

      // Determine if revertToOld flag should be true
      let revertToOld = false;
      if (this.tripDetails.is_checklist_submitted && validStatusForOdometer) {
        this.showOdometerDialog = true;
        console.log("188", this.newStatus, this.oldStatus);
      } else {
        // Check if we need to revert status change

        if (
          (this.newStatus === "Reached Drop Location" &&
            this.oldStatus === "Completed") ||
          (this.newStatus === "Reached Pickup Location" &&
            this.oldStatus === "Reached Drop Location") ||
          (this.newStatus === "Enroute" &&
            this.oldStatus === "Reached Pickup Location") ||
          (this.newStatus === "Enroute" && this.oldStatus === "Completed") ||
          (this.newStatus === "Enroute" &&
            this.oldStatus === "Reached Drop Location") ||
          (this.newStatus === "Reached Pickup Location" &&
            this.oldStatus === "Completed")
        ) {
          revertToOld = true;
        }
        this.changeTripStatus(this.newStatus, revertToOld);
      }
    },

    // updateTripStatus(val) {
    //   let validStatusForOdometer = false;
    //   this.oldStatus = this.tripDetails.status;
    //   this.newStatus = this.tripMilestones[val];
    //   if (["Enroute", "Completed"].indexOf(this.newStatus) > -1) {
    //     if (this.newStatus == "Enroute" && this.oldStatus == "Assigned") {
    //       validStatusForOdometer = true;
    //     } else if (
    //       this.newStatus == "Completed" &&
    //       [
    //         "Assigned",
    //         "Enroute",
    //         "Reached Pickup Location",
    //         "Reached Drop Location",
    //       ].indexOf(this.oldStatus) > -1
    //     ) {
    //       validStatusForOdometer = true;
    //     }
    //   }
    //   if (
    //     this.tripDetails &&
    //     this.tripDetails.is_checklist_submitted &&
    //     validStatusForOdometer
    //   ) {
    //     this.showOdometerDialog = true;
    //   } else {
    //     this.changeTripStatus(this.newStatus, true);
    //   }
    // },
    async refreshTripDetails() {
      await this.getTripMatrix(this.$route.params.id);
      await this.getTripDetails(this.$route.params.id);
    },
    changeTripStatus(status, revertToOld = false) {
      if (confirm("Are you sure you want to change status")) {
        this.$bus.$emit("showLoader", true);
        this.$api.trip
          .changeTripStatus(this.tripDetails.id, status, revertToOld)
          .then((res) => {
            this.$bus.$emit("showToastMessage", {
              message: "Trip Status updated!",
              color: "success",
            });
            this.$bus.$emit("showLoader", false);
            this.getTripDetails(this.$route.params.id);
          })
          .catch((err) => {
            this.$bus.$emit("showToastMessage", {
              message:
                err.data && err.data.non_field_errors
                  ? err.data.non_field_errors[0]
                  : "Couldn't update Trip status!",
              color: "error",
            });
            this.getTripDetails(this.$route.params.id);

            if (revertToOld) {
              this.tripNumaricalStatus = this.oldtripNumaricalStatus;
            }
            this.$bus.$emit("showLoader", false);
          });
      } else {
        if (revertToOld) {
          this.tripNumaricalStatus = this.oldtripNumaricalStatus;
        }
        return;
      }
    },

    revertTripStatus() {
      this.tripNumaricalStatus = this.oldtripNumaricalStatus;
    },
    callBackRoute() {
      this.$router.push("/app/admin/trip/list");
    },

    async getTripDetails(id) {
      this.$bus.$emit("showLoader", true);
      await this.$api.trip
        .getTripObject(id)
        .then(async (res) => {
          this.tripDetails = res.data;
          if (
            this.tripDetails.mid_point &&
            this.tripDetails.destination_point
          ) {
            this.waypoints = [];
            this.waypoints.push({
              location: {
                lat: parseFloat(this.tripDetails.mid_point.latitude),
                lng: parseFloat(this.tripDetails.mid_point.longitude),
              },
              stopover: false,
            });
          } else {
            this.waypoints = [];
            this.tripDetails.destination_point = this.tripDetails.mid_point;
            this.tripDetails.mid_point = null;
          }
          this.tripNumaricalStatus = this.tripMilestones.indexOf(
            res.data.status
          );
          this.oldtripNumaricalStatus = this.tripMilestones.indexOf(
            res.data.status
          );

          if (
            ["Ready For Invoice", "Invoice Generated"].indexOf(
              res.data.status
            ) != -1
          ) {
            this.tripNumaricalStatus = 4;
            this.oldtripNumaricalStatus = 4;
          }
          this.getMilestoneList();

          this.getRoute();

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getRoute() {
      if (
        (this.tripDetails.source_point && this.tripDetails.destination_point) ||
        (this.tripDetails.source_point && this.tripDetails.mid_point) ||
        (this.tripDetails.source_point &&
          this.tripDetails.mid_point &&
          this.tripDetails.destination_point)
      ) {
        if (this.directionsTimeout) {
          clearTimeout(this.directionsTimeout);
        }

        this.directionsTimeout = setTimeout(() => {
          const directionConfig = {
            origin: {
              lat: parseFloat(this.tripDetails.source_point.latitude),
              lng: parseFloat(this.tripDetails.source_point.longitude),
            },
            destination: {
              lat: parseFloat(this.tripDetails.destination_point.latitude),
              lng: parseFloat(this.tripDetails.destination_point.longitude),
            },
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
          };
          if (this.waypoints && this.waypoints.length) {
            directionConfig.waypoints = this.waypoints;
          }

          this.getDirections(directionConfig)
            .then((directions) => {
              this.directions = directions;
              this.selectedRoute = this.directions[0];

              this.loadRoute();
              this.loadMarkers();
            })
            .catch((err) => {
              console.error(err);
              this.$refs.transporterTripDetailsMap.removeGeoJson();
              this.loadMarkers();
            });
        }, 200);
      }
    },

    getDirections(directionConfig) {
      return new Promise((resolve, reject) => {
        this.$refs.transporterTripDetailsMap
          .getDirections(directionConfig)
          .then(async (resp) => {
            const routes = resp.routes;
            let directions = [];

            let index = 0;
            while (routes.length > directions.length) {
              directions.push({
                name: `Route ${index + 1}`,
                distance: routes[index].legs[0].distance.text,
                route:
                  this.$refs.transporterTripDetailsMap.generateGeoJsonFromLineString(
                    await this.$refs.transporterTripDetailsMap.generateLineStringFromRoute(
                      routes[index]
                    )
                  ),
              });
              index++;
            }

            resolve(directions);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    loadRoute() {
      if (this.markerTimeout) {
        clearTimeout(this.markerTimeout);
      }
      this.markerTimeout = setTimeout(() => {
        this.tripDetails = {
          ...this.tripDetails,
          route: this.selectedRoute.route,
          distance: this.selectedRoute.distance,
        };

        this.$refs.transporterTripDetailsMap.addGeoCollection(
          this.tripDetails.route
        );

        if (
          this.driverLocationDetails &&
          this.driverLocationDetails.actual_route &&
          this.driverLocationDetails.actual_route.features &&
          this.driverLocationDetails.actual_route.features[0].properties
        ) {
          this.driverLocationDetails.actual_route.features[0].properties.actual_route = true;
          this.$refs.transporterTripDetailsMap.addGeoCollection(
            this.driverLocationDetails.actual_route,
            false
          );
        }
      }, 500);
    },

    loadMarkers() {
      const markers = [
        { ...this.tripDetails.source_point, type: "source" },
        { ...this.tripDetails.destination_point, type: "destination" },
      ];
      if (this.tripDetails.mid_point && this.tripDetails.destination_point) {
        markers.push({ ...this.tripDetails.mid_point, type: "mid" });
      }

      if (
        this.driverLocationDetails &&
        this.driverLocationDetails.last_driver_location &&
        this.driverLocationDetails.last_driver_location.location
      ) {
        markers.push({
          ...this.driverLocationDetails.last_driver_location.location,
          type: "truck",
          speed: this.driverLocationDetails.last_driver_location.speed,
          device_id: this.driverLocationDetails.last_driver_location.device_id,
          vehicle_id:
            this.driverLocationDetails.last_driver_location.vehicle_number,
        });
      }

      this.$refs.transporterTripDetailsMap.addMultipleMarkers(markers);
    },

    // getRoute() {
    //   // Ensure that trip details and trip_route_json are available
    //   if (this.tripDetails && this.tripDetails.trip_route_json) {
    //     if (this.directionsTimeout) {
    //       clearTimeout(this.directionsTimeout);
    //     }

    //     this.directionsTimeout = setTimeout(() => {
    //       const tripRoute = this.tripDetails.trip_route_json;
    //       const directions = [
    //         {
    //           name: "Route 1",
    //           distance: tripRoute.distance_in_km,
    //           route: tripRoute.planed_route,
    //         },
    //       ];

    //       this.loadRoute();
    //       this.loadMarkers();
    //     }, 200);
    //   }
    // },

    // loadRoute() {
    //   if (this.markerTimeout) {
    //     clearTimeout(this.markerTimeout);
    //   }
    //   this.markerTimeout = setTimeout(() => {
    //     // Use the first route provided by getDirections
    //     if (this.tripDetails.trip_route_json) {
    //       this.tripDetails = {
    //         ...this.tripDetails,
    //         route: this.tripDetails.trip_route_json.planed_route,
    //         distance: this.tripDetails.trip_route_json.distance_in_km,
    //       };
  

    //       // Add the planned route to the map
    //       this.$refs.transporterTripDetailsMap.addGeoCollection(
    //         this.tripDetails.route
    //       );
    //     }

    //     // Add actual route if available
    //     if (
    //       this.driverLocationDetails &&
    //       this.driverLocationDetails.actual_route &&
    //       this.driverLocationDetails.actual_route.features &&
    //       this.driverLocationDetails.actual_route.features[0].properties
    //     ) {
    //       this.driverLocationDetails.actual_route.features[0].properties.actual_route = true;
    //       this.$refs.transporterTripDetailsMap.addGeoCollection(
    //         this.driverLocationDetails.actual_route,
    //         false
    //       );
    //     }
    //   }, 500);
    // },

    // loadMarkers() {
    //   const markers = [
    //     { ...this.tripDetails.source_point, type: "source" },
    //     { ...this.tripDetails.destination_point, type: "destination" },
    //   ];
    //   if (this.tripDetails.mid_point && this.tripDetails.destination_point) {
    //     markers.push({ ...this.tripDetails.mid_point, type: "mid" });
    //   }

    //   if (
    //     this.driverLocationDetails &&
    //     this.driverLocationDetails.last_driver_location &&
    //     this.driverLocationDetails.last_driver_location.location
    //   ) {
    //     markers.push({
    //       ...this.driverLocationDetails.last_driver_location.location,
    //       type: "truck",
    //       speed: this.driverLocationDetails.last_driver_location.speed,
    //       device_id: this.driverLocationDetails.last_driver_location.device_id,
    //       vehicle_id:
    //         this.driverLocationDetails.last_driver_location.vehicle_number,
    //     });
    //   }

    //   this.$refs.transporterTripDetailsMap.addMultipleMarkers(markers);
    // },

    getMilestoneList() {
      this.$api.tripMilestone
        .getTripMilestonesList({
          trip: this.tripDetails.id,
        })
        .then((res) => {
          this.milestoneList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async getTripMatrix(id) {
      await this.$api.trip
        .getTripMatrix(id)
        .then((resp) => {
          this.driverLocationDetails = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    tripMilestoneReached() {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .TripMilestoneReached(this.tripDetails.id, {
          trip_milestone: this.milestone,
        })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.milestone = null;
          this.getTripDetails(this.$route.params.id);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    checkQuery() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("currentCal" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.currentCal = this.$route.query.currentCal;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },

    submitTripLogsForm() {
      this.$bus.$emit("showLoader", true);
      this.tripLogErrors = {};

      let params = {
        ...this.tripLogDetails,
        log: this.tripDetails.id,
        charge:
          typeof this.tripLogDetails.charge == typeof {}
            ? this.tripLogDetails.charge.id
            : this.tripLogDetails.charge,
      };

      if (!this.editTripLogMode) {
        params.tripLogId = this.tripLogDetails.id;
        this.$api.trip
          .addTrip(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Trip Log added successfully!",
              color: "success",
            });

            this.getTripDetails(this.$route.params.id);

            this.resetForm();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Log charge!",
              color: "error",
            });
            this.tripLogErrors = err.data;
          });
      } else {
        params.tripLogId = this.tripLogDetails.id;
        this.$api.trip
          .updateTripLogs({
            data: params,
          })
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Log updated successfully!",
              color: "success",
            });
            this.getTripDetails(this.$route.params.id);
            this.resetForm();
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Log!",
              color: "error",
            });
            this.tripLogErrors = err.data;
          });
      }
    },
  },
  created() {
    this.$bus.$on("socketMessageReceived", (data) => {});
  },
  async beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("leg_trip");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        await this.getTripMatrix(this.$route.params.id);
        await this.getTripDetails(this.$route.params.id);
        this.checkQuery();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.logsContainer {
  height: 500px;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.new-msg-btn {
  position: absolute;
  bottom: 78px;
  right: 32px;
  z-index: 1;
}
</style>
